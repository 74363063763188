<template>
  <div
    v-if="visible"
    id="chatbot-container"
    ref="chatbotFrame"
    class="chatbot-frame"
    @mousedown="startDrag"
  >
    <iframe
      src="https://kaisetsu-chat.com/chatbot/client/?seqid=wr_5677NJ&room_id=635"
      frameborder="0"
    ></iframe>
    <div class="cover" ref="cover"></div>
    <div class="resize-handle" @mousedown.prevent="startResize"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      dragging: false,
      offsetX: 0,
      offsetY: 0,
      isResizing: false,
      firstWidth: 400,
      firstHeight: 500,
    };
  },
  mounted() {
    const toggleBtn = document.getElementById("chatbot-toggle");
    if (toggleBtn) {
      toggleBtn.addEventListener("click", this.toggleChatbot);
    }
  },
  methods: {
    toggleChatbot() {
      this.visible = !this.visible;
    },
    // startDrag(event) {
    //   const coverHeight = this.$refs.cover.getBoundingClientRect().height;
    //   const squareFrame = this.$refs.chatbotFrame.getBoundingClientRect();

    //   if (event.clientY < squareFrame.top + coverHeight) {
    //     this.dragging = true;
    //     this.offsetX = event.clientX - squareFrame.left;
    //     this.offsetY = event.clientY - squareFrame.top;

    //     this.addListeners(this.onDrag, this.stopDrag)
    //   }
    // },
    // onDrag(event) {
    //   if (this.dragging) {
    //     const x = event.clientX - this.offsetX;
    //     const y = event.clientY - this.offsetY;
    //     this.$refs.chatbotFrame.style.left = `${x}px`;
    //     this.$refs.chatbotFrame.style.top = `${y}px`;
    //   }
    // },
    // stopDrag() {
    //   this.dragging = false;
    //   this.removeListeners(this.onDrag, this.stopDrag)
    // },
    // startResize(event) {
    //   this.isResizing = true;
    //   this.firstWidth = this.$refs.chatbotFrame.clientWidth;
    //   this.firstHeight = this.$refs.chatbotFrame.clientHeight;
    //   this.offsetX = event.clientX;
    //   this.offsetY = event.clientY;

  //     this.addListeners(this.onResize, this.stopResize);
  //   },
  //   onResize(event) {
  //     if (this.isResizing) {
  //       const x = event.clientX - this.offsetX;
  //       const y = event.clientY - this.offsetY;
  //       let newWidth = this.firstWidth + x;
  //       let newHeight = this.firstHeight + y;

  //       const MAX_WIDTH = 700;
  //       const MIN_WIDTH = 350;
  //       const MIN_HEIGHT = 200;

  //       newWidth = Math.max(MIN_WIDTH, Math.min(MAX_WIDTH, newWidth));
  //       newHeight = Math.max(MIN_HEIGHT, newHeight);

  //       this.$refs.chatbotFrame.style.width = `${newWidth}px`;
  //       this.$refs.chatbotFrame.style.height = `${newHeight}px`;

  //       this.$refs.chatbotFrame.style.width = `${newWidth}px`;
  //       this.$refs.chatbotFrame.style.height = `${newHeight}px`;
  //     }
  //   },
  //   stopResize() {
  //     this.isResizing = false;
  //     this.removeListeners(this.onResize, this.stopResize);
  //   },
  //   addListeners(moveHandler, stopHandler) {
  //     window.addEventListener("mousemove", moveHandler);
  //     window.addEventListener("mouseup", stopHandler);
  //   },
  //   removeListeners(moveHandler, stopHandler) {
  //     window.removeEventListener("mousemove", moveHandler);
  //     window.removeEventListener("mouseup", stopHandler);
  //   },
  },
};
</script>

<style scoped>
.chatbot-frame {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 400px;
  height: 500px;
  min-width: 350px;
  max-width: 700px;
  min-height: 200px;
  z-index: 1050;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  cursor: default;
  resize: both;
}

.chatbot-frame iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 90%;
  height: 20%;
  background: transparent;
  z-index: 1025;
  /* cursor: move; */
}

.close-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.resize-handle {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 20px;
  height: 20px;
  background-color: transparent;
  /* cursor: se-resize; */
}
</style>
